import React from 'react';
import Button, { ButtonSize } from '@soosap/sushi/Button';
import { Carte2 } from 'server/src/app/carte/types';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import PDFIcon from 'icons/PDF';

const isClient = typeof window !== 'undefined';

import MenuItem from './components/MenuItem';

export interface Props {
  carte: Carte2;
}

const MenuCard: React.FC<Props> = ({ carte }) => {
  return (
    <Container className={`MenuCard`}>
      <div className="MenuCard__download">
        <Button
          link
          icon={PDFIcon}
          size={ButtonSize.BIG}
          title="Herunterladen"
          href="/THAMARAI-Restaurant-Speisekarte.pdf"
          download
          onClick={() => {
            if (isClient) {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'call-to-action',
                category: 'Carte',
                action: 'Download',
                label: 'Speisekarte',
              });
            }
          }}
        />
      </div>
      {carte.buckets
        .sort((a, b) => a.position - b.position)
        .map(bucket => {
          const applicableOffers = bucket.offers
            .filter(offer => offer.active)
            .sort((a, b) => a.position - b.position);

          return (
            <React.Fragment key={bucket.id}>
              <Title className="MenuCard__title">{bucket.title}</Title>
              <Container.Inner>
                <ul className="MenuCard__bucket">
                  {applicableOffers.map((offer, index) => (
                    <MenuItem
                      key={offer.id}
                      offer={offer}
                      bucketIndex={index}
                      bucketTotal={applicableOffers.length}
                    />
                  ))}
                </ul>
              </Container.Inner>
            </React.Fragment>
          );
        })}
    </Container>
  );
};

export default MenuCard;
