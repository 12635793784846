import React from 'react';
import Icon, { IconTheme } from '@soosap/sushi/Icon';
import times from 'lodash.times';
import { Offer2, FoodItem } from 'server/src/app/carte/types';
import BananaLeafIcon from 'icons/BananaLeaf';
import ChiliIcon from 'icons/Chili';

export interface Props {
  offer: Offer2;
  bucketIndex: number;
  bucketTotal: number;
}

const MenuItem: React.FC<Props> = ({ offer, bucketIndex, bucketTotal }) => {
  const product = offer.product as FoodItem;

  return (
    <li className={`MenuItem`}>
      <h5 className="MenuItem__title">
        <span className="MenuItem__heading">
          {product.title}
          {product.subtitle && (
            <div className="MenuItem__subtitle">{product.subtitle}</div>
          )}
        </span>
        {['veg/an', 'vegan', 'veg'].includes(product.vegetarian as string) && (
          <span className="MenuItem__veg">{product.vegetarian}</span>
        )}
      </h5>
      <div className="MenuItem__title-in-tamil">{product.titleInTamil}</div>
      <div className="MenuItem__spiciness">
        {times(Number(product.spiciness), index => (
          <Icon key={index} className="MenuItem__chili" svg={ChiliIcon} />
        ))}
      </div>
      <div className="MenuItem__description">
        {product.description}&nbsp;
        <span className="MenuItem__price">
          {(offer.quotes[0].price / 100).toFixed(2)}
        </span>
      </div>
      {bucketIndex + 1 < bucketTotal && (
        <div className="MenuItem__divider">
          <Icon
            className={`MenuItem__leaf MenuItem__leaf--${bucketIndex % 3}`}
            svg={BananaLeafIcon}
            theme={IconTheme.SECONDARY}
          />
        </div>
      )}
    </li>
  );
};

export default MenuItem;
