import React from 'react';
import { GatsbyImageProps } from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'molecules/Layout';
import MenuCard from 'organisms/MenuCard';
import { Carte2 } from 'server/src/app/carte/types';
import 'styles/pages/speisekarte.scss';

export const MENU_CARD_QUERY = graphql`
  query MenuCardQuery {
    logo: file(relativePath: { eq: "logos/thamarai-speisekarte-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carte(id: { eq: "abendkarte" }) {
      id
      buckets {
        id
        title
        position
        offers {
          id
          product {
            ... on FoodItem {
              id
              title
              titleInTamil
              subtitle
              description
              spiciness
              vegetarian
            }
          }
          active
          position
          quotes {
            id
            price
            position
          }
          variants {
            checked
            delta
            iconName
            id
            position
            title
          }
        }
      }
    }
  }
`;

interface Data {
  logo: {
    childImageSharp: GatsbyImageProps;
  };
  carte: Carte2;
}

interface Props {}

const MenuCardPage: React.FC<Props> = () => {
  const { logo, carte } = useStaticQuery<Data>(MENU_CARD_QUERY);
  return (
    <Layout
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/speisekarte-tamilische-spezialitaeten"
          canonical="/speisekarte-tamilische-spezialitaeten"
          title="Speisekarte"
          description="Das THAMARAI bereichert die Restaurant Landschaft der Sontheimer Altstadt mitten in Heilbronn mit authentisch tamilischer Kochkunst aus Sri Lanka und Indien"
          lang="de-DE"
        />
      )}
      header={() => (
        <Layout.Header
          title="Speisekarte"
          subtitle="À La Carte"
          abstract={`Die tamilische Küche steht für eine sehr facettenreiche, bunte, würzige und über Jahrtausende entstandene Kochkunst. Sie hat die benachbarten indischen Küchen maßgeblich mit beeinflusst und wird oft als Synonym für die Südindische Küche verstanden. Doch gibt es auch im tamilischsprachigen Raum Unterschiede. So weist die Küche der Sri Lanka Tamilen andere Klassiker auf wie die der Indien Tamilen. Manches wird aber auch beiderorts gleichermaßen zelebriert. Das THAMARAI strebt nach einer modernen Interpretation unserer Landesküche und gleichzeitig nach Bewahrung unserer Kultur und Tradition. Einige Speisen der tamilisch-indischen Küche erfordern eine beachtliche Vorbereitungszeit und können nicht "À La Carte" angeboten werden. Dennoch möchten wir Sie im Rahmen von sogenannten wechselnden "Spezialwochen" in den Genuß dieser außergewöhnlichen und spannenden Speisen kommen lassen. Nun freuen wir uns Ihnen unsere Speisekarte vorstellen zu dürfen.`}
          image={logo.childImageSharp}
          alt="Exklusives Logo für die Speisekarte"
        />
      )}
    >
      <div className={`MenuCardPage`}>
        <MenuCard carte={carte} />
      </div>
    </Layout>
  );
};

export default MenuCardPage;
